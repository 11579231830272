import { useCookies } from "vue3-cookies";
import { v4 as uuidv4 } from "uuid";

const { cookies } = useCookies();

// HTTP заголовок для авторизации
let authToken = cookies.get("_auth_token");
if (typeof authToken == "undefined" || authToken === "" || authToken == null) {
	authToken = uuidv4();
	cookies.set("_auth_token", authToken);
}

export default authToken
import api from "@/api/axios";
import storeCallback from "../../utils/storeCallback";

export default {
	state: {
		data: [],
	},
	actions: {
		getConsumables({commit}, data) {
			return api.get("/manage/consumables/get").then(response => {
				commit("setConsumables", response.data);
				storeCallback(data);
			});
		},
		saveConsumable({commit}, data) {
			api.post("/manage/consumables/set", data.data).then(() => {
				commit("addConsumable", data.data);
				storeCallback(data);
			}).catch(() => {
				storeCallback(data, data.data);
			});
		},
		deleteConsumable({commit}, data) {
			api.post("/manage/consumables/delete", data.data).then(() => {
				commit("deleteConsumable", data.data);
				storeCallback(data);
			}).catch(() => {
				storeCallback(data, data.data);
			});
		},
	},
	mutations: {
		addConsumable(state, value) {
			state.data = [...state.data, ...value];
		},
		setConsumables(state, value) {
			state.data = value.filter(item => item.isDeleted != 1);
		},
		deleteConsumable(state, value) {
			state.data = state.data.filter(item => item.idConsumable !== value.idConsumable);
		}
	}
};

import api from "@/api/axios";
import storeCallback from "../../utils/storeCallback";

export default {
	state: {
		data: [],
		month: {},
		purchases: [],
		addData: []
	},
	actions: {
		getReport({ commit }, data) {
			commit("setReport", []);
			let params = { ...{}, ...data };
			
			delete (params.callback);
			
			return api.get(`/manage/report/get`, { params }).then(response => {
				commit("setReport", response.data);
				storeCallback(data);
				
				return response;
			});
		},
		getPurchases({ commit }, data) {
			let params = { ...{}, ...data };
			
			delete (params.callback);
			
			return api.get(`/manage/purchases/get`, { params }).then(response => {
				commit("setPurchases", response.data);
				storeCallback(data);
				
				return response;
			});
		},
		getAddData({ commit }, params) {
			return api.get(`/manage/report/additional`, { params }).then(response => {
				commit("setAdditional", response.data);
			});
		},
		deleteAddData({ commit }, id) {
			return commit("deleteAdditional", id);
		},
		setAddData(c, data) {
			return api.post(`/manage/report/set-additional`, data.data).then(() => {
				storeCallback(data);
			});
		},
		savePurchases({ commit }, data) {
			api.post("/manage/purchases/set", data.data).then(() => {
				commit("savePurchases", data.data);
				storeCallback(data);
			}).catch(() => {
				storeCallback(data, data.data);
			});
		},
		getReportMonth({ commit }, data) {
			commit("setReportMonth", []);
			let params = { ...{}, ...data };
			
			delete (params.callback);
			
			return api.get(`/manage/report-month/get`, { params }).then(response => {
				commit("setReportMonth", response.data);
				storeCallback(data);
			});
		},
		getReportMonthOfDay({ commit }, params) {
			return api.get(`/manage/report-month/get`, { params }).then(response => {
				commit("saveReportMonth", response.data);
				
				return response;
			});
		},
		saveReportMonth({ commit }, data) {
			const nullValues = [ 'reportMonthAdmin', 'reportMonthClients', 'reportMonthPotential' ];
			nullValues.forEach(name => {
				data.data = data.data.map(item => {
					if (!item || !item[name]) {
						item[name] = 0;
					}
					return item;
				})
			});
			
			return api.post("/manage/report-month/set", { data: data.data, params: data.params }).then(response => {
				commit("saveReportMonth", response.data);
				storeCallback(data);
				return true;
			}).catch((e) => {
				if (!!e.response && !!e.response.data && !!e.response.data.message) {
					storeCallback(data, { message: e.response.data.message, data: data.data });
				} else {
					storeCallback(data, { data: data.data });
				}
				return false;
			});
		},
		saveReport({ commit }, data) {
			return api.post("/manage/report/set", data.data).then(() => {
				commit("saveReport", data.data);
				storeCallback(data);
				return true;
			}).catch(e => {
				storeCallback(data, data.data);
				return false;
			});
		},
		deleteReport({ commit }, data) {
			api.post("/manage/report/delete", data.data).then(() => {
				commit("deleteReport", data.data);
				storeCallback(data);
			}).catch(() => {
				storeCallback(data, data.data);
			});
		}
	},
	mutations: {
		setReport(state, value) {
			state.data = value;
		},
		setPurchases(state, value) {
			state.purchases = value;
		},
		setAdditional(state, value) {
			if (state.addData.length > 0 && !Array.isArray(value)) {
				state.addData = [ ...state.addData.filter(item => item.idRmad !== value.idRmad), value ];
			} else {
				state.addData = value
			}
		},
		deleteAdditional(state, value) {
			state.addData = state.addData.filter(item => item.idRmad !== value);
		},
		deleteReport(state, value) {
			state.data = state.data.filter(item => item.idReport !== value.idReport);
		},
		setReportMonth(state, value) {
			state.month = Array.isArray(value) ? value.reduce((acc, item) => ({
				...acc,
				[item.reportMonthDate]: item
			}), {}) : {};
		},
		saveReportMonth(state, value) {
			let thisState = state.month;
			
			state.month = {}
			
			value.forEach(item => {
				thisState[item.reportMonthDate] = item;
			});
			
			state.month = thisState;
		},
		savePurchases(state, value) {
			value.forEach(data => {
				const report = state.purchases.find(item => item.idPurchases === data.idPurchases);
				if (!!report && !!report.idPurchases) {
					state.purchases = state.purchases.map(item => (item.idPurchases === data.idPurchases ? data : item));
				} else {
					state.purchases = [ ...state.purchases, data ];
				}
			});
		},
		saveReport(state, value) {
			if (value)
				value.forEach(reportData => {
					const report = state.data.find(item => item.idReport === reportData.idReport);
					if (!!report && !!report.idReport) {
						state.data = state.data.map(item => (item.idReport === reportData.idReport ? reportData : item));
					} else {
						state.data = [ ...state.data, reportData ];
					}
				});
		}
	}
};

<template>
  <h2>Типы задач</h2>

  <a-skeleton v-if="loading.types" active/>
  <div class="tasks-wrap" v-else>
    <Sortable
        :list="modelTypes"
        item-key="taskTypesOrder"
        @end="handleTypeSort"
    >
      <template #item="{element, index}">
        <div class="draggable" :key="element.taskTypeName">
          <div class="left">
            <VerticalAlignMiddleOutlined/>

            <div class="name tasks-type-color"
                 :style="{background: element.taskTypeColor, color: element.taskTypeColorText}">
              {{ element.taskTypeName }}
            </div>
          </div>

          <a-button class="hidden" size="small" @click="() => handleEditType(index)">
            <template #icon>
              <EditOutlined/>
            </template>
          </a-button>
        </div>
      </template>
    </Sortable>

    <a-modal
        wrapClassName="tasks-type-new-modal top-z-index-model"
        :visible="model.type.editable"
        v-if="model.type.editable"
        :footer="false"
        :closable="false"
    >
      <h4>Название</h4>
      <a-input v-model:value="model.type.taskTypeName"/>

      <div class="colors mTop10" v-if="model.type.editable">
        <div>
          <h4>Цвет фона</h4>
          {{ model.type.taskTypeColor }}
          <Vue3ColorPicker
              v-model="model.type.taskTypeColor"
              mode="solid"
              :showColorList="false"
              :showEyeDrop="false"
              :showAlpha="false"
              :showInputMenu="false"
              :showInputSet="false"
              type="HEX"
          />
        </div>

        <div>
          <h4>Цвет текста</h4>
          {{ model.type.taskTypeColorText }}
          <Vue3ColorPicker
              v-model="model.type.taskTypeColorText"
              mode="solid"
              :showColorList="false"
              :showEyeDrop="false"
              :showAlpha="false"
              :showInputMenu="false"
              :showInputSet="false"
              type="HEX"
          />
        </div>
      </div>

      <div class="buttons mTop20">
        <a-button size="small" @click="handleNoEditType">Отменить</a-button>
        <a-button class="mLeft10" size="small" type="primary" @click="handleSaveType">Применить</a-button>
      </div>
    </a-modal>

    <div class="add-button" @click="handleAddType">
      <plus-circle-outlined/>
      Добавить новый тип
    </div>

    <div class="save-button" v-if="changing.types">
      <a-button size="small" @click="initTypes">Отменить</a-button>
      <a-button size="small" type="primary" @click="handleSaveTypes">Сохранить</a-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Sortable } from "sortablejs-vue3";
import lodash from 'lodash';
import { Vue3ColorPicker } from '@cyhnkckali/vue3-color-picker';
import '@cyhnkckali/vue3-color-picker/dist/style.css'
import { orderSort } from "@/utils/sort";

const defaultTaskType = (order = 1000, editable = true) => ({
  taskTypeName: '',
  taskTypeColor: '#cccccc',
  taskTypeColorText: '#ffffff',
  taskTypeColorShow: false,
  taskTypesOrder: order,
  editable: editable
});

const defaultTaskTypes = () => []

export default {
  name: "SettingsTasksTypes",
  props: [ 'save' ],
  components: { Sortable, Vue3ColorPicker },
  data: function () {
    return {
      model: {
        type: defaultTaskType(1000, false),
        types: defaultTaskTypes(),
        typesChanged: Array.from({ length: defaultTaskTypes().length }, (v, i) => i),
      },
      changing: {
        types: false,
      },
      loading: {
        types: true,
      }
    }
  },
  computed: {
    ...mapGetters({
      auth: "auth",
      settings: "settings",
      taskTypes: "taskTypes",
    }),

    modelTypes() {
      return orderSort(this.model.types, 'taskTypes')
    },
  },
  watch: {
    taskTypes() {
      this.init()
    }
  },
  created() {
    this.initTypes()
  },
  methods: {
    ...mapActions([
      "startLoading",
      "stopLoading",
      "getApiData",
      "setApiData"
    ]),

    setTypes(data) {
      setTimeout(() => {
        this.model.types = data;
        this.model.typesChanged = Array.from({ length: this.model.types.length }, (v, i) => i)
        this.changing.types = false;
        this.loading.types = false;
      }, 400);
    },

    initTypes() {
      this.loading.types = true;

      this.getApiData({ type: 'TaskTypes', }).then(data => {
        if (data) {
          this.setTypes(data)
        }
      })
    },

    init() {
      this.model.types = this.taskTypes;
    },

    handleAddType() {
      let maxOrder = 0;
      this.model.types.forEach(item => {
        if (item.taskTypesOrder > maxOrder || maxOrder == 0) {
          maxOrder = item.taskTypesOrder;
        }
      })

      maxOrder += 10;

      this.model.typesChanged.push(this.model.typesChanged.length)
      this.model.types.push(defaultTaskType(maxOrder, false))
      this.handleEditType(this.model.types.length - 1)
    },

    handleTypeSort(event) {
      let newTypes = [];

      this.model.typesChanged.forEach((item, key) => {
        if (key == event.oldIndex) {

        } else {
          if (event.oldIndex < event.newIndex) {
            newTypes.push(item);
          }

          if (key == event.newIndex) {
            newTypes.push(event.oldIndex);
          }

          if (event.oldIndex > event.newIndex) {
            newTypes.push(item);
          }
        }
      })

      this.model.typesChanged = newTypes
      this.changing.types = true
    },

    handleEditType(key) {
      this.model.type = { ...lodash.cloneDeep(this.model.types[key]), editable: true, key: key };
    },

    handleNoEditType() {
      this.model.type = {};
    },

    handleSaveType() {
      this.model.types[this.model.type.key] = lodash.cloneDeep(this.model.type);
      this.model.type = {}
      this.changing.types = true;

      this.handleNoEditType();
    },

    handleSaveTypes() {
      this.startLoading();

      let types = [];
      let counter = 10;
      this.model.typesChanged.forEach(item => {
        types.push({ ...this.model.types[item], taskTypesOrder: counter });

        counter += 10;
      })

      this.setApiData({
        type: 'TaskTypes', data: types
      }).then(data => {
        if (this.save) {
          this.save();
        }

        this.loading.types = true;
        this.changing.types = false;
        this.model.types = [];

        this.setTypes(data)

        this.startLoading({ message: 'Статусы сохранены', status: 'success', close: true });
      }).catch((e) => {
        console.error(e);
        this.startLoading({ message: 'Ошибка сохранения', status: 'error', close: true });
      })
    },
  }
};
</script>

<style lang="less" scoped>
h2 {
  margin-bottom: 10px;
}

.tasks-wrap {
  margin-bottom: 30px;
  width: 300px;

  &:last-child {
    margin-bottom: 0;
  }

  .save-button {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: end;

    .ant-btn {
      margin-left: 20px;
    }
  }

  .add-button {
    margin-top: 10px;
    padding: 6px 10px 6px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      color: #4096ff;
    }

    .anticon {
      font-size: 20px;
      margin-right: 12px;
    }
  }

  .draggable {
    border: 1px #f0f0f0 solid;
    margin-top: -1px;
    background: white;
    cursor: pointer;
    padding: 6px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
    }

    .anticon-vertical-align-middle {
      margin-right: 10px;
    }

    .ant-btn.hidden {
      display: none;
    }

    &:hover {
      .ant-btn.hidden {
        display: block;
      }
    }

    .name {
      height: 24px;
      line-height: 24px;
      padding: 0 6px;
      border-radius: 5px;
    }
  }
}
</style>

<style lang="less">
.tasks-type-new-modal {
  .colors {
    display: flex;
    justify-content: space-between;
  }

  .ck-cp-container {
    width: 230px;
    box-shadow: none;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 8px;

    .colour-area, .colour-area-mask {
      border-radius: 4px;
    }
  }
}
</style>
<template>
  <settings-tasks-statuses/>
  <settings-tasks-types/>
</template>

<script>
import '@cyhnkckali/vue3-color-picker/dist/style.css'
import SettingsTasksStatuses from "@/views/data/settings/tasksStatuses";
import SettingsTasksTypes from "@/views/data/settings/tasksTypes";


export default {
  name: "SettingsTasks",
  components: { SettingsTasksTypes, SettingsTasksStatuses },
};
</script>
import authToken from "@/utils/authToken";
import api from "@/api/axios";
import { mapActions, mapGetters } from "vuex";
import { notification } from "ant-design-vue";

let isChecked = true

export default {
	computed: {
		...mapGetters({
			connection: "WSConnection",
			auth: "auth"
		}),
	},
	methods: {
		...mapActions([ 'WSConnectionOpen', 'WSConnect' ]),
		
		connectionReady() {
			return this.connection && authToken && this.connection.readyState == 1;
		},
		
		async checkConnection(data) {
			if (this.connectionReady()) {
				return true;
			} else {
				if (this.connection.readyState == 3) {
					if (isChecked === false) {
						let counter = 0;
						while (isChecked === false) {
							await new Promise(() => setTimeout(() => {
							}, 500));
							
							counter++;
							
							if (counter == 5) {
								isChecked = true;
							}
						}
					} else {
						isChecked = false;
						return await api.get("/manage/websocket/check?get=1").then(async res => {
							isChecked = true;
							
							if (res.data && res.data.success) {
								if (this.auth.status == true) {
									this.WSConnect();
									this.WSConnectionOpen({
										groups: this.auth.groups,
										idUser: this.auth.user.idUser,
										idBranch: this.auth.currentBranch,
										funct: () => {
											if (this.connectionReady()) {
												this.addWSSubscription(data);
											}
										}
									});
								}
							}
							
							return false;
						})
					}
					
					if (this.connectionReady()) {
						return true;
					}
				}
				
				return false;
			}
		},
		
		addWSSubscription(data) {
			let interval = setInterval(() => {
				this.checkConnection(data).then(checked => {
					if (checked) {
						clearInterval(interval);
						
						this.connection.send(JSON.stringify({ action: 'addSubscription', data }));
					} else {
						if (this.connection.readyState == 3) {
							/*notification.open({
								placement: 'bottomRight',
								duration: 0,
								description: 'Возникли проблемы с подключением к серверу. Обратитесь к разработчику.',
							});*/
							
							clearInterval(interval);
						}
					}
				})
			}, 500);
		}
	}
};
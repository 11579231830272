export const getJsonStorage = (name) => {
	const data = localStorage.getItem(name)
	
	if (!!data) {
		return JSON.parse(data);
	}
	
	return false;
}

export const setJsonStorage = (name, data) => {
	localStorage.setItem(name, JSON.stringify(data))
}
import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";
import VueCookies from 'vue3-cookies'
import Antd from 'ant-design-vue';
import { Button } from "ant-design-vue";

import "ant-design-vue/dist/reset.css";
import 'v-calendar/dist/style.css';

import "./layouts/style/style.less";
import addComponents from "@/utils/addComponents";
import generateId from "@/utils/generateId";

let app = createApp(App);

app.config.globalProperties.globalStaticUrl = 	process.env.NODE_ENV === "production"
		? "https://static.o4biz.ru/"
		: "http://static.marmelad.ru";

addComponents(app)

console.log(generateId());

app.use(VueCookies, {
	expireTimes: "30d",
	path: "/",
	domain: "",
	secure: true,
	sameSite: "None"
});

app
	.use(Antd)
	.use(Button)
	.use(store)
	.use(router)
	.mount('#app')

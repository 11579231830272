export const nameSort = (arr, name) => arr.sort((a, b) => {
	let nameA = !!a[name] && a[name] !== undefined ? String(a[name]).toLowerCase() : ''
	let nameB = !!b[name] ? b[name].toLowerCase() : '';
	
	if (nameA < nameB) {
		return -1;
	}
	
	if (nameA > nameB) {
		return 1;
	}
	
	return 0;
})

export const nameSortBackward = (arr, name) => arr.sort((a, b) => {
	let nameA = !!a[name] && a[name] !== undefined ? String(a[name]).toLowerCase() : ''
	let nameB = !!b[name] ? b[name].toLowerCase() : '';
	
	if (nameA < nameB) {
		return 1;
	}
	
	if (nameA > nameB) {
		return -1;
	}
	
	return 0;
})

export const orderSort = (arr, name) => arr.sort((a, b) => {
	return a[`${ name }Order`] - b[`${ name }Order`];
})

export const numberSort = (arr, name) => arr.sort((a, b) => {
	return Number(a[`${ name }`]) - Number(b[`${ name }`]);
})
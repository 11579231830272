import api from "@/api/axios";
import storeCallback from "../../utils/storeCallback";

export default {
	state: {
		data: [],
		all: [],
		groups: [],
		groupsAll: []
	},
	actions: {
		getServices({ commit }, data) {
			return api.get("/manage/services/get" + (!!data && data.all ? '?all=1' : '')).then(response => {
				commit("setServices", response.data);
				storeCallback(data);
			});
		},
		getOnlineServices({ commit }, data) {
			return api.get("/manage/services/get?online=1").then(response => {
				commit("setServices", response.data);
				storeCallback(data);
			});
		},
		saveService({ commit }, data) {
			api.post("/manage/services/set", data.data).then(() => {
				commit("addServices", data.data);
				storeCallback(data);
			}).catch(() => {
				storeCallback(data, data.data);
			});
		},
		deleteService({ commit }, data) {
			api.post("/manage/services/delete", data.data).then(() => {
				commit("deleteService", data.data);
				storeCallback(data);
			}).catch(() => {
				storeCallback(data, data.data);
			});
		},
		getServiceGroups({ commit }, data) {
			return api.get("/manage/services/get-groups").then(response => {
				commit("setServiceGroup", response.data);
				storeCallback(data);
			});
		},
		saveServiceGroup({ commit }, data) {
			api.post("/manage/services/set-group", data.data).then(() => {
				commit("addServiceGroup", data.data);
				storeCallback(data);
			}).catch(() => {
				storeCallback(data, data.data);
			});
		},
		deleteServiceRelation({ commit }, data) {
			api.post("/manage/services/delete-relation", data.data).then(() => {
				commit("deleteServiceRelation", data.data);
				storeCallback(data);
			}).catch(() => {
				storeCallback(data, data.data);
			});
		}
	},
	mutations: {
		addServices(state, value) {
			state.data = [ ...state.data, ...value ];
			state.all = [ ...state.all, ...value ];
		},
		addServiceGroup(state, value) {
			state.groups = [ ...state.groups, ...value ];
			state.groupsAll = [ ...state.groupsAll, ...value ];
		},
		setServices(state, value) {
			state.data = value.filter(item => item.isDeleted != 1);
			state.all = value;
		},
		setServiceGroup(state, value) {
			state.groups = value.filter(item => item.isDeleted != 1);
			state.groupsAll = value;
		},
		saveServices(state, value) {
			let ids = [];
			value.forEach(item => {
				ids.push(item.idService);
			})
			
			state.data = [ ...state.data.filter(item => !ids.includes(item.idService)), ...value ];
			state.all = [ ...state.all.filter(item => !ids.includes(item.idService)), ...value ];
		},
		deleteService(state, value) {
			state.data = state.data.filter(item => item.idService != value.idService);
		},
		deleteServiceRelation(state, value) {
			state.data = state.data.map(item => {
				if (item.idService != value.idService) {
					item.serviceGroupRelations = item.serviceGroupRelations.filter(relation => value.idServiceGroupRelation != relation.idServiceGroupRelation);
				}
				
				return item;
			});
			state.all = state.all.map(item => {
				if (item.idService != value.idService) {
					item.serviceGroupRelations = item.serviceGroupRelations.filter(relation => value.idServiceGroupRelation != relation.idServiceGroupRelation);
				}
				
				return item;
			});
		}
	}
};

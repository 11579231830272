import {
	PlusCircleOutlined,
	CheckCircleOutlined,
	CheckOutlined,
	CloseOutlined,
	DatabaseOutlined,
	SettingOutlined,
	CaretDownOutlined,
	EditOutlined,
	PlusSquareOutlined,
	CopyOutlined,
	DeleteOutlined,
	MinusCircleOutlined,
	UserOutlined,
	ReadOutlined,
	TableOutlined,
	LoadingOutlined,
	LeftOutlined,
	RightOutlined,
	UserAddOutlined,
	PlusOutlined,
	MinusOutlined,
	SearchOutlined,
	InfoCircleOutlined,
	ShoppingCartOutlined,
	ContainerOutlined,
	QuestionCircleOutlined,
	MailOutlined,
	AndroidOutlined,
	RedoOutlined,
	ScheduleOutlined,
	ArrowRightOutlined,
	CalendarOutlined,
	WalletOutlined,
	TeamOutlined,
	RocketOutlined,
	ForwardFilled,
	CarOutlined,
	FileSearchOutlined,
	EnterOutlined,
	VerticalAlignMiddleOutlined,
	ExclamationCircleOutlined,
	SortAscendingOutlined,
	FormOutlined,
	SwapOutlined,
	SortDescendingOutlined
} from '@ant-design/icons-vue';

export default (app) => {
	app.component('PlusCircleOutlined', PlusCircleOutlined);
	app.component('PlusOutlined', PlusOutlined);
	app.component('InfoCircleOutlined', InfoCircleOutlined);
	app.component('CheckCircleOutlined', CheckCircleOutlined);
	app.component('CheckOutlined', CheckOutlined);
	app.component('CloseOutlined', CloseOutlined);
	app.component('DatabaseOutlined', DatabaseOutlined);
	app.component('SettingOutlined', SettingOutlined);
	app.component('CaretDownOutlined', CaretDownOutlined);
	app.component('EditOutlined', EditOutlined);
	app.component('CopyOutlined', CopyOutlined);
	app.component('DeleteOutlined', DeleteOutlined);
	app.component('MinusCircleOutlined', MinusCircleOutlined);
	app.component('MinusOutlined', MinusOutlined);
	app.component('UserOutlined', UserOutlined);
	app.component('ReadOutlined', ReadOutlined);
	app.component('TableOutlined', TableOutlined);
	app.component('LoadingOutlined', LoadingOutlined);
	app.component('LeftOutlined', LeftOutlined);
	app.component('RightOutlined', RightOutlined);
	app.component('UserAddOutlined', UserAddOutlined);
	app.component('SearchOutlined', SearchOutlined);
	app.component('ContainerOutlined', ContainerOutlined);
	app.component('ShoppingCartOutlined', ShoppingCartOutlined);
	app.component('QuestionCircleOutlined', QuestionCircleOutlined);
	app.component('AndroidOutlined', AndroidOutlined);
	app.component('MailOutlined', MailOutlined);
	app.component('RedoOutlined', RedoOutlined);
	app.component('ScheduleOutlined', ScheduleOutlined);
	app.component('ForwardFilled', ForwardFilled);
	app.component('ArrowRightOutlined', ArrowRightOutlined);
	app.component('CalendarOutlined', CalendarOutlined);
	app.component('WalletOutlined', WalletOutlined);
	app.component('TeamOutlined', TeamOutlined);
	app.component('RocketOutlined', RocketOutlined);
	app.component('CarOutlined', CarOutlined);
	app.component('ExclamationCircleOutlined', ExclamationCircleOutlined);
	app.component('FileSearchOutlined', FileSearchOutlined);
	app.component('VerticalAlignMiddleOutlined', VerticalAlignMiddleOutlined);
	app.component('PlusSquareOutlined', PlusSquareOutlined);
	app.component('EnterOutlined', EnterOutlined);
	app.component('SortAscendingOutlined', SortAscendingOutlined);
	app.component('SortDescendingOutlined', SortDescendingOutlined);
	app.component('SwapOutlined', SwapOutlined);
	app.component('FormOutlined', FormOutlined);
}
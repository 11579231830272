<template>
  <icon-logo/>
</template>

<script>
import IconLogo from "@/components/icons/Logo";

export default {
  name: "MaLogo",
  components: { IconLogo },
};
</script>

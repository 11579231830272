import moment from "moment";

let time = Date.now();
let timeout = false;
let timeoutSecDefault = 1000;
let timeoutSec = 1000;

const setTime = (text) => {
	timeoutSec = text.length * 0.05 * 1000;
}

const stopLoadingCallback = () => {
	return false;
}

export default {
	state: {
		loading: false,
		loadingMessage: '',
		loadingStatus: '',
		loadingNeedClose: '',
		changes: {},
		changesTime: {},
		loadingHideMask: false,
		stopLoadingCallback: stopLoadingCallback
	},
	actions: {
		stopLoading({ commit }) {
			commit("setLoading", false);
		},
		async startLoading({ commit }, data = '') {
			if (timeout) {
				clearTimeout(timeout);
			}
			
			await commit("setLoading", true);
			await commit("setLoadingStatus", '');
			
			if (!!data) {
				if (!!data.add) {
					if (state.loadingMessage == '') {
						commit("setLoadingMessage", data.add);
					} else {
						commit("addLoadingMessage", data.add);
					}
				} else if (!!data.message) {
					commit("setLoadingMessage", data.message);
				} else {
					commit("setLoadingMessage", data);
				}
				
				time = Date.now();
				if (!!data.status) {
					commit("setLoadingStatus", data.status);
				}
				
				if (!!data.hideMask) {
					commit("setLoadingHideMask", true);
				}
				
				if (!!data.stopLoadingCallback) {
					commit("setStopLoadingCallback", data.stopLoadingCallback);
				}
				
				if (!!data.close) {
					timeout = setTimeout(() => {
						commit("setLoading", false);
					}, (!!data.time ? (data.time * 2000) : 100));
				}
			} else {
				time = (Date.now() + 3000);
				commit("setLoadingStatus", '');
				commit("setLoadingHideMask", false);
				commit("setStopLoadingCallback", stopLoadingCallback);
			}
			
			return true;
		},
		setChanges({ commit }, data) {
			commit("setChanges", data);
		},
		checkChanges({ commit }) {
			commit("checkChanges");
		},
	},
	mutations: {
		setLoading(state, value) {
			if (!value) {
				const difference = Date.now() - time;
				
				if (difference < 0) {
					state.loading = value;
					state.loadingMessage = '';
				} else {
					setTimeout(() => {
						state.stopLoadingCallback();
						state.stopLoadingCallback = stopLoadingCallback;
						state.loading = value;
						state.loadingMessage = '';
					}, (timeoutSec - difference));
				}
			} else {
				state.loading = value;
			}
		},
		setLoadingNeedClose(state, value) {
			state.loadingNeedClose = value;
		},
		setLoadingMessage(state, value) {
			timeoutSec = timeoutSecDefault;
			
			state.loadingMessage = value;
			setTime(state.loadingMessage);
		},
		setLoadingStatus(state, value) {
			state.loadingStatus = value;
		},
		setChanges(state, { name, status }) {
			state.changes = { ...state.changes, [name]: status };
			state.changesTime = { ...state.changesTime, [name]: moment().unix() };
		},
		setLoadingHideMask(state, value) {
			state.loadingHideMask = value;
		},
		checkChanges(state) {
			state.changes = Object.entries(state.changes).reduce((acc, [ key, item ]) => {
				if (!!state.changesTime && !!state.changesTime[key]) {
					if (state.changesTime[key] > 0 && moment().unix() - state.changesTime[key] >= 10) {
						return acc
					}
				}
				
				return { ...acc, [key]: item }
			}, {})
		},
		setStopLoadingCallback(state, value) {
			state.stopLoadingCallback = value;
		}
	}
};

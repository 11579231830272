import axios from "axios";
import { getApiUrl } from "@/utils/getUrls";
import authToken from "@/utils/authToken";

const headers = {
  Authorization: `Bearer ${authToken}`,
};

export const version = '1.08.24'

export default axios.create({
  withCredentials: false,
  headers: headers,
  baseURL: getApiUrl
});
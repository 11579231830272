export const timeHours = (from = 0, to = 24) => {
	let times = [];
	for (let i = from; i <= to; i++) {
		times.push(padNum(i));
	}
	
	return times;
};

export const timeMinutes = (counter= 15) => {
	let times = [];
	for (let i = 0; i < 60; i = i + counter) {
		times.push(padNum(i));
	}
	
	return times;
};

export const padNum = num => num.toString().padStart(2, 0);
import { LOCAL_STORE_RECORD_SERVICE, LOCAL_STORE_RECORD_SPECIALIST } from "@/utils/defaults/local-store";
import api from "@/api/axios";


export default {
	state: {
		service: localStorage.getItem(LOCAL_STORE_RECORD_SERVICE) || '',
		specialist: localStorage.getItem(LOCAL_STORE_RECORD_SPECIALIST) || '',
		date: '',
		time: '',
		
		activeDates: {},
		error: '',
		errorConfirm: {},
		
		confirm: false,
		
		details: {
			service: '',
			specialist: '',
			date: '',
			time: '',
		}
	},
	getters: {
		recordService: state => state.service,
		recordSpecialist: state => state.specialist,
		recordTime: state => state.time,
		recordDate: state => state.date,
		
		recordActiveDates: state => state.activeDates,
		recordError: state => state.error,
		
		recordConfirm: state => state.confirm,
		recordDetails: state => state.details,
		recordErrorConfirm: state => state.errorConfirm
	},
	actions: {
		setRecordService({ commit }, data) {
			commit("setRecordService", data);
		},
		setRecordSpecialist({ commit }, data) {
			commit("setRecordSpecialist", data);
		},
		setRecordDate({ commit }, data) {
			commit("setRecordDate", data);
		},
		setRecordTime({ commit }, data) {
			commit("setRecordTime", data);
		},
		recordAgain({ commit }, data) {
			commit("recordAgain", data);
		},
		clearDatesTimes({ commit }, data) {
			commit("recordAgain", data);
		},
		
		getRecordTimes({ commit, state }) {
			api.get(`/manage/records/get-time?serv=${ state.service }&spec=${ state.specialist }`)
				.then(res => {
					if (res.data.error) {
						commit("setRecordActiveDates", {});
						commit("setRecordError", res.data.error);
					} else {
						commit("setRecordActiveDates", res.data.dates);
					}
				})
				.catch(() => {
					commit("setRecordActiveDates", {});
				})
		},
		
		addRecord({ commit, state }, data) {
			let params = {
				...data,
				service: state.service,
				specialist: state.specialist,
				date: state.date,
				time: state.time,
			}
			
			api.post(`/manage/records/add`, params)
				.then(res => {
					if (res.data.error) {
						commit("setRecordError", res.data.error);
					} else if (res.data.errorConfirm) {
						commit("setRecordErrorConfirm", res.data.errorConfirm);
					} else {
						commit("setRecordDetails");
						
						commit("setRecordService", '');
						commit("setRecordSpecialist", '');
						commit("setRecordError", '');
						commit("setRecordDate", '');
						commit("setRecordTime", '');
						commit("setRecordActiveDates", {});
						commit("recordAgain", true);
					}
				})
				.catch(() => {
					commit("setRecordError", 'Произошла непредвиденная ошибка, попробуйте позже.');
				})
		}
	},
	mutations: {
		setRecordService(state, value) {
			localStorage.setItem(LOCAL_STORE_RECORD_SERVICE, value);
			
			state.service = value;
		},
		setRecordSpecialist(state, value) {
			localStorage.setItem(LOCAL_STORE_RECORD_SPECIALIST, value);
			
			state.specialist = value;
		},
		setRecordError(state, value) {
			state.error = value;
		},
		recordAgain(state, value = false) {
			state.confirm = value;
		},
		setRecordDate(state, value) {
			state.date = value;
		},
		setRecordTime(state, value) {
			state.time = value;
		},
		setRecordActiveDates(state, value) {
			state.activeDates = value;
		},
		setRecordErrorConfirm(state, value) {
			state.errorConfirm = value;
		},
		
		setRecordDetails(state) {
			state.details = {
				recordService: state.service,
				recordSpecialist: state.specialist,
				recordDate: state.date,
				recordTime: state.time,
			}
		}
	}
};

import { mapGetters } from "vuex";
import settings from "@/views/data/settings/index.vue";

export default {
	computed: {
		...mapGetters({
			branchSettings: "branchSettings"
		})
	},
	methods: {
		checkSettings(name, param, canBeNull = true) {
			return !!this.branchSettings[name] && !!this.branchSettings[name][param] && this.branchSettings[name][param] == 1 || canBeNull && !this.branchSettings[name];
		},
		
		getSettingName(name, defaultVal) {
			return !!this.branchSettings['names'] && !!!!this.branchSettings['names'][name] ? this.branchSettings['names'][name] : defaultVal;
		}
	}
};
import api from "@/api/axios";
import storeCallback from "../../utils/storeCallback";

export default {
	state: {
		data: [],
	},
	actions: {
		getProducts({ commit }, data) {
			return api.get("/manage/products/get").then(response => {
				commit("setProducts", response.data);
				storeCallback(data);
			});
		},
		saveProduct({ commit }, data) {
			api.post("/manage/products/set", data.data).then(() => {
				commit("saveProduct", data.data);
				storeCallback(data);
			}).catch(() => {
				storeCallback(data, data.data);
			});
		},
		deleteProduct({ commit }, data) {
			api.post("/manage/products/delete", data.data).then(() => {
				commit("deleteProduct", data.data);
				storeCallback(data);
			}).catch(() => {
				storeCallback(data, data.data);
			});
		},
	},
	mutations: {
		saveProduct(state, value) {
			const oldData = state.data.find(item => item.idProduct === value.idProduct)
			if (oldData) {
				state.data = state.data.map(item => {
					if (item.idProduct === value.idProduct) {
						return value;
					}
					return item;
				});
			} else {
				state.data = [ ...state.data, value ];
			}
		},
		setProducts(state, value) {
			state.data = value.filter(item => Number(item.isDeleted) !== 1);
		},
		deleteProduct(state, value) {
			state.data = state.data.filter(item => item.idProduct !== value.idProduct);
		}
	}
};

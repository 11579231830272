import api from "@/api/axios";
import lodash from "lodash";
import { defaultTaskStatuses } from "@/utils/tasks";

const apiUrl = "/api"

export default {
	state: {
		data: {
			Settings: [],
			Loyalty: [],
			UserGroups: [],
			Tasks: [],
			TaskStatuses: [],
			TaskTypes: [],
			TaskUsers: [],
			DocsMenu: [],
		}
	},
	getters: {
		settings: state => state.data.Settings,
		userGroups: state => state.data.UserGroups,
		loyalty: state => state.data.Loyalty,
		tasks: state => state.data.Tasks,
		taskStatuses: state => state.data.TaskStatuses,
		taskTypes: state => state.data.TaskTypes,
		taskUsers: state => state.data.TaskUsers,
		docsMenu: state => state.data.DocsMenu,
	},
	actions: {
		getApiData({ commit }, data) {
			return api.get(`${ apiUrl }/get/`, { params: data }).then(response => {
				commit("setData", {
					type: data.type,
					data: response.data
				});
				
				return response.data;
			});
		},
		getOnlineData({ commit }, data) {
			const name = data.type.charAt(0).toLowerCase() + data.type.slice(1)
			
			return api.get(`${ apiUrl }/get-${name}`, { params: data }).then(response => {
				commit("setData", {
					type: data.type,
					data: response.data
				});
				
				return response.data;
			});
		},
		setApiData({ commit }, data) {
			return api.post(
				`${ apiUrl }/set/?type=${ data.type }${ data.returnSaved ? '&returnSaved=1' : '' }`,
				{ 'data': data.data, checkDuplicates: data.checkDuplicates }
				)
				.then((response) => {
					if (response.data.errors) {
						return response.data;
					} else {
						commit("setData", {
							type: data.type,
							data: response.data
						});
						
						return response.data;
					}
				});
		},
		deleteApiData({ commit }, data) {
			return api.delete(`${ apiUrl }/delete/?type=${ data.type }`, { params: data.data }).then((response) => {
				commit("deleteData", {
					type: data.type,
					data: response.data
				});
			});
		},
	},
	mutations: {
		setData(state, data) {
			if (data.type == 'TaskStatuses') {
				if (data.data.length == 0) {
					data.data = defaultTaskStatuses()
				}
			}
			
			if (data.type == 'Settings') {
				data.data = data.data.reduce((acc, item) => {
					if (item.settingValue !== '') {
						acc[item.settingName] = item.settingName === 'schedule' ? JSON.parse(item.settingValue) : item.settingValue;
					}
					
					return acc;
				}, {})
			}
			state.data = { ...state.data, [data.type]: data.data }
		},
		deleteData(state, data) {
			if (state.data[data.type]) {
				state.data = {
					...state.data,
					[data.type]: state.data[data.type].filter(item => (item[data.data.key] !== data.data.value))
				}
			}
		}
	}
};

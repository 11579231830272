import api from "@/api/axios";
import storeCallback from "../../utils/storeCallback";
import dayjs from "dayjs";
import md5 from "js-md5";

export default {
	state: {
		allCategories: [],
		categories: [],
		masters: [],
		admins: [],
		users: [],
		fetched: {
			masters: 0,
			admins: 0,
			users: 0,
		},
		hash: {
			masters: '',
			admins: ''
		}
	},
	actions: {
		getMasters({ commit, state }, data) {
			const fetched = state.fetched.masters;
			const hash = state.hash.masters;
			const url = "/manage/masters/get?group=3" + (!!data && data.all ? '&all=1' : '');
			const hashUrl = md5(url);
			
			if (dayjs().unix() - fetched > 3 || state.masters.length === 0 || hash != hashUrl) {
				return api.get(url).then(response => {
					state.fetched.masters = dayjs().unix();
					state.hash.masters = hashUrl;
					commit("setMasters", response.data);
					storeCallback(data);
				});
			}
		},
		getAdmins({ commit, state }, data) {
			const fetched = state.fetched.admins;
			const hash = state.hash.admins;
			const url = "/manage/masters/get?group=1" + (!!data && data.all ? '&all=1' : '');
			const hashUrl = md5(url);
			
			if (dayjs().unix() - fetched > 3 || state.admins.length === 0 || hash != hashUrl) {
				return api.get(url).then(response => {
					state.fetched.admins = dayjs().unix();
					state.hash.admins = hashUrl;
					commit("setAdmins", response.data);
					storeCallback(data);
				});
			}
		},
		getUsers({ commit, state }, data) {
			const fetched = state.fetched.users;
			
			if (dayjs().unix() - fetched > 3 || state.users.length === 0) {
				return api.get("/manage/masters/get?group=all").then(response => {
					state.fetched.users = dayjs().unix();
					commit("setUsers", response.data);
					storeCallback(data);
				});
			}
		},
		saveUser(c, data) {
			api.post("/manage/masters/set", data.data).then(() => {
				storeCallback(data);
			}).catch(() => {
				storeCallback(data, data.data);
			});
		},
		saveCategory({ commit }, data) {
			api.post("/manage/masters/save-category", data.data).then(response => {
				commit("setCategory", response.data);
				storeCallback(data);
			}).catch(() => {
				storeCallback(data, data.data);
			});
		},
		deleteUser({ commit }, data) {
			api.post("/manage/masters/delete", data.data).then(() => {
				commit("deleteUser", data.data);
				storeCallback(data);
			}).catch(() => {
				storeCallback(data, data.data);
			});
		},
		getCategories({ commit }, data) {
			api.post("/manage/masters/categories").then((response) => {
				commit("setCategories", response.data);
				storeCallback(data);
			}).catch(() => {
				storeCallback(data);
			});
		}
	},
	mutations: {
		deleteUser(state, value) {
			state.users = state.users.filter(item => item.idUser != value.idUser);
			state.masters = state.masters.filter(item => item.idUser != value.idUser);
			state.admins = state.admins.filter(item => item.idUser != value.idUser);
		},
		addUser(state, value) {
			if (value.idGroup == 3) {
				state.masters = [ ...state.masters, value ];
			}
			
			if (value.idGroup == 3) {
				state.admins = [ ...state.admins, value ];
			}
		},
		setMasters(state, value) {
			state.masters = value;
		},
		
		setCategory(state, value) {
			let isSet = state.allCategories.find(item => item.idCategory == value.idCategory);
			
			state.categories = isSet ?
				state.categories.map(item => item.idCategory == value.idCategory ? value : item) :
				[ ...state.categories, value ].filter(item => item.isDeleted == 0);
			state.allCategories = isSet ?
				state.allCategories.map(item => item.idCategory == value.idCategory ? value : item) :
				[ ...state.allCategories, value ];
		},
		
		setCategories(state, value) {
			state.categories = value.filter(item => item.isDeleted == 0);
			state.allCategories = value;
		},
		deleteCategory(state, value) {
			state.categories = value.filter(item => item.isDeleted == 0);
			state.allCategories = value;
		},
		setUsers(state, value) {
			state.users = value;
		},
		setAdmins(state, value) {
			state.admins = value;
		}
	}
};

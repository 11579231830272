import generateId from "@/utils/generateId";

export const newTaskModel = () => ({
	taskName: '',
	taskType: '',
	taskText: '',
})

export const newCommentModel = () => ({
	idUser: '',
	taskCommentText: '',
	taskCommentHours: '',
	taskCommentMinutes: '',
	taskCommentTime: ''
})

export const defaultTaskStatuses = () => [
	{
		idTaskStatus: 0,
		taskStatusName: 'В ожидании',
		taskStatusType: '0',
		taskStatusOrder: 10,
	},
	
	{
		idTaskStatus: 1,
		taskStatusName: 'Выполняется',
		taskStatusType: '1',
		taskStatusOrder: 20,
	},
	
	{
		idTaskStatus: 2,
		taskStatusName: 'Готово',
		taskStatusType: '2',
		taskStatusOrder: 30,
	},
]
import dayjs from "dayjs";

export default {
	state: {
		currentDate: {
			day: new Date(),
			dayJs: dayjs()
		},
	},
	getters: {
		currentDay: state => state.currentDate.day,
		currentDayJs: state => state.currentDate.dayJs,
	},
	actions: {
		setDay({commit}, date) {
			commit("setDay", date);
		},
		setDayJs({commit}, date) {
			commit("setDayJs", date);
		},
	},
	mutations: {
		setDay(state, value) {
			state.currentDate.day = value;
			state.currentDate.dayJs = dayjs(value);
		},
		setDayJs(state, value) {
			state.currentDate.day = new Date(value.format('YYYY-MM-DD'));
			state.currentDate.dayJs = value;
		},
	}
};

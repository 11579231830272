import api from "@/api/axios";

export default {
	state: {
		data: [],
	},
	getters: {
		schedules: state => state.data.reduce((acc, item) => {
			if (!acc[item.scheduleDate]) {
				acc[item.scheduleDate] = {}
			}
			
			if (!acc[item.scheduleDate][item.idUser]) {
				acc[item.scheduleDate][item.idUser] = {}
			}
			
			if (!acc[item.scheduleDate][item.idUser][item.scheduleType]) {
				acc[item.scheduleDate][item.idUser][item.scheduleType] = []
			}
			
			acc[item.scheduleDate][item.idUser][item.scheduleType].push(item)
			
			return acc;
		}, {}),
	},
	actions: {
		clearSchedules({commit}) {
			commit("clearSchedules");
		},
		getSchedules({commit}, data) {
			return api.get("/manage/schedules/get", {params: data}).then(response => {
				commit("setSchedules", {clear: true, response: response.data});
			});
		},
		saveSchedule({commit}, data) {
			return api.post("/manage/schedules/set", data).then(response => {
				commit("setSchedules", {data: data, response: response.data});
			});
		},
		deleteSchedule({commit}, data) {
			return api.post("/manage/schedules/delete", data).then(() => {
				commit("deleteSchedule", data);
			});
		},
	},
	mutations: {
		setSchedules(state, value) {
			if (value.clear) {
				state.data = [];
			}
			
			let data = state.data;
			
			if (value.data) {
				data = data.filter(item => !(
						value.data.days.includes(item.scheduleDate) &&
						value.data.users.map(Number).includes(Number(item.idUser))
					)
				);
			}
			
			state.data = [...data, ...value.response];
		},
		saveSchedule(state, value) {
			if (value)
				value.forEach(scheduleData => {
					const schedule = state.data.find(item => item.idSchedule === scheduleData.idSchedule);
					if (!!schedule && !!schedule.idSchedule) {
						state.data = state.data.map(item => (item.idSchedule === scheduleData.idSchedule ? scheduleData : item));
					} else {
						state.data = [ ...state.data, scheduleData ];
					}
				});
		},
		deleteSchedule(state, value) {
			state.data = state.data.filter(item => !value.idSchedule.includes(item.idSchedule));
		},
		clearSchedules(state) {
			state.data = [];
		}
	}
};

import { nameSort, orderSort } from "@/utils/sort";

export default {
	auth: state => state.user.auth,
	authCodeFlag: state => state.user.codeFlag,
	authError: state => state.user.error,
	authBranch: state => state.user.branch,
	
	loading: state => state.loading.loading,
	loadingMessage: state => state.loading.loadingMessage,
	loadingNeedClose: state => state.loading.loadingNeedClose,
	loadingStatus: state => state.loading.loadingStatus,
	changes: state => state.loading.changes,
	
	report: state => state.report.data,
	reportMonths: state => state.report.month,
	rmAddData: state => state.report.addData.reduce((acc, item) => {
		const key = `${ item.rmadDate }-${ item.idUser }-${ item.rmadType }`;
		
		if (acc[key]) {
			acc[key] = [ ...acc[key], item ];
		} else {
			acc[key] = [ item ];
		}
		
		return acc;
	}, {}),
	
	services: state => state.services.data,
	serviceGroups: state => state.services.groups,
	serviceGroupsAll: state => state.services.groupsAll,
	servicesAll: state => state.services.all,
	masters: state => state.masters.masters,
	admins: state => state.masters.admins.reduce((acc, item) => ({ ...acc, [Number(item.idUser)]: item }), {}),
	users: state => state.masters.users,
	categories: state => state.masters.categories,
	allCategories: state => state.masters.allCategories,
	
	mastersObj: state => state.masters.masters.reduce((acc, item) => ({ ...acc, [Number(item.idUser)]: item }), {}),
	
	preferences: state => state.preferences.data,
	preferencesAll: state => state.preferences.all,
	preferencesAllObj: state => state.preferences.all.reduce((acc, item) => ({ ...acc, [item.idPreference]: item }), {}),
	sales: state => state.sales.data,
	salesAll: state => state.sales.all,
	saleGroups: state => state.sales.groups,
	saleGroupsAll: state => state.sales.groupsAll,
	purchases: state => state.report.purchases,
	delivery: state => state.delivery.data,
	
	warehouses: state => nameSort(state.warehouses.data, 'warehouseName'),
	products: state => state.products.data,
	productsById: state => state.products.data.reduce((acc, item) => ({ ...acc, [item.idObject]: item }), {}),
	consumables: state => orderSort(state.consumables.data, 'consumable'),
	
	timetable: state => state.timetable.data,
};

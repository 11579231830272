import api from "@/api/axios";
import storeCallback from "../../utils/storeCallback";
import generateId from "@/utils/generateId";

export default {
	state: {
		data: [],
		all: []
	},
	actions: {
		getDelivery({ commit }, data) {
			let params = { ...{}, ...data };
			
			delete (params.callback);
			
			return api.get("/manage/delivery/get", { params }).then(response => {
				commit("setDelivery", response.data);
				storeCallback(data);
			});
		},
		saveDelivery({ commit }, data) {
			api.post("/manage/delivery/set", { data: data.data, newId: generateId() }).then(() => {
				commit("saveDelivery", data.data);
				storeCallback(data);
			}).catch(() => {
				storeCallback(data, data.data);
			});
		},
		deleteDelivery({ commit }, data) {
			api.post("/manage/delivery/delete", data.data).then(() => {
				commit("deleteDelivery", data.data);
				storeCallback(data);
			}).catch(() => {
				storeCallback(data, data.data);
			});
		},
	},
	mutations: {
		setDelivery(state, value) {
			state.data = value.filter(item => item.isDeleted != 1);
			state.all = value;
		},
		saveDelivery(state, value) {
			let ids = [];
			value.forEach(item => {
				ids.push(item.idDelivery);
			})
			
			state.data = [ ...state.data.filter(item => !ids.includes(item.idDelivery)), ...value ];
			state.all = [ ...state.all.filter(item => !ids.includes(item.idDelivery)), ...value ];
		},
		deleteDelivery(state, value) {
			state.data = state.data.filter(item => item.idDelivery != value.idDelivery);
		}
	}
};

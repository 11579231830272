import { createStore } from 'vuex'

import getters from "./getters";
import user from "./modules/user";
import loading from "./modules/loading";
import report from "./modules/report";
import masters from "./modules/masters";
import services from "./modules/services";
import preferences from "./modules/preferences";
import sales from "./modules/sales";
import delivery from "./modules/delivery";
import warehouses from "./modules/warehouses";
import consumables from "./modules/consumables";
import products from "./modules/products";
import schedule from "./modules/schedule";
import api from "./modules/api";
import timetable from "./modules/timetable";
import date from "./modules/date";
import record from "./modules/record";
import reportMonth from "./modules/reportMonth";
import closeDay from "./modules/closeDay";
import websocket from "./modules/websocket";
import tasks from "./modules/tasks";

export default createStore({
	modules: {
		user,
		loading,
		report,
		masters,
		services,
		sales,
		delivery,
		preferences,
		warehouses,
		products,
		schedule,
		consumables,
		timetable,
		date,
		record,
		reportMonth,
		closeDay,
		websocket,
		tasks,
		api
	},
	getters
});

import { createRouter, createWebHistory } from "vue-router";

import HomeView from '@/views/HomeView'

export const routes = [
	{
		path: "/",
		name: "HomeView",
		component: HomeView
	},
	{
		path: "/docs",
		name: "Docs",
		component: () => import("@/views/docs"),
	},
	{
		path: "/docs/:id",
		name: "DocsDock",
		component: () => import("../views/docs"),
	},
	{
		path: "/tasks",
		name: "Tasks",
		component: () => import("@/views/tasks"),
		children: [
			{
				name: "TasksPlanning",
				path: "/tasks/planning",
				component: () => import("@/views/tasks/planning"),
			},
			{
				name: "TasksList",
				path: "/tasks/list",
				component: () => import("@/views/tasks/list")
			},
			{
				name: "TasksBoard",
				path: "/tasks/board",
				component: () => import("@/views/tasks/board")
			},
		]
	},
	{
		path: "/record",
		name: "Record",
		component: () => import("@/views/record"),
		children: [
			{
				name: "ClientAuth",
				path: "/record/auth",
				component: () => import("@/views/record/clientAuth")
			},
			{
				name: "ClientProfile",
				path: "/record/client",
				component: () => import("@/views/record/clientProfile")
			},
			{
				name: "ClientHistory",
				path: "/record/history",
				component: () => import("@/views/record/clientHistory")
			},
		]
	},
	{
		path: "/summary",
		name: "Summary",
		component: () => import("@/views/summary"),
		children: [
			{
				name: "SummaryTimetable",
				path: "/summary/timetable",
				component: () => import("@/views/summary/timetable")
			},
			{
				name: "SummarySchedule",
				path: "/summary/schedule",
				component: () => import("@/views/summary/schedule")
			},
		]
	},
	{
		name: "Report",
		path: "/report",
		component: () => import("@/views/report")
	}, {
		name: "Calendar",
		path: "/calendar",
		component: () => import("@/views/calendar"),
		children: [
			{
				name: "CalendarTimetable",
				path: "/calendar/timetable",
				component: () => import("@/views/calendar/timetable")
			},
			{
				name: "CalendarSchedule",
				path: "/calendar/schedule",
				component: () => import("@/views/calendar/schedule")
			},
			{
				name: "CalendarDelivery",
				path: "/calendar/delivery",
				component: () => import("@/views/calendar/delivery")
			},
			{
				name: "CalendarSales",
				path: "/calendar/sales",
				component: () => import("@/views/calendar/sales")
			}
		]
	},
	{
		name: "CostPrices",
		path: "/data/cost-prices",
		component: () => import("@/views/data/cost-prices")
	},
	{
		name: "Profile",
		path: "/profile",
		component: () => import("@/views/profile")
	},
	{
		name: "Notifications",
		path: "/notifications",
		component: () => import("@/views/notifications")
	},
	{
		name: "Clients",
		path: "/data/clients",
		component: () => import("@/views/data/clients")
	},
	{
		name: "Preferences",
		path: "/data/preferences",
		component: () => import("@/views/data/preferences")
	},
	{
		name: "Sales",
		path: "/data/sales",
		component: () => import("@/views/data/sales")
	},
	{
		name: "Users",
		path: "/data/users",
		component: () => import("@/views/data/users")
	},
	{
		name: "Certificates",
		path: "/data/certificates",
		component: () => import("@/views/data/certificates")
	},
	{
		name: "Settings",
		path: "/data/settings",
		component: () => import("@/views/data/settings")
	},
	{
		name: "Consumables",
		path: "/data/consumables",
		component: () => import("@/views/data/consumables")
	},
	{
		name: "MonthReport",
		path: "/month-report",
		component: () => import("@/views/month-report")
	},
	{
		name: "Warehouse",
		path: "/warehouse",
		component: () => import("@/views/warehouse")
	}
];

export default createRouter({
	history: createWebHistory(),
	routes
});
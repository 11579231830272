import api from "@/api/axios";
import storeCallback from "../../utils/storeCallback";

export default {
  state: {
    data: [],
    all: []
  },
  actions: {
    getPreferences({ commit }, data) {
      api.get("/manage/preferences/get" + (!!data && data.all ? '?all=1' : '')).then(response => {
        commit("setPreferences", response.data);
        storeCallback(data);
      });
    },
    savePreference(c , data) {
      api.post("/manage/preferences/set", data.data).then(() => {
        storeCallback(data);
      }).catch(() => {
        storeCallback(data, data.data);
      });
    },
    deletePreference({ commit }, data) {
      api.post("/manage/preferences/delete", data.data).then(() => {
        commit("deletePreference", data.data);
        storeCallback(data);
      }).catch(() => {
        storeCallback(data, data.data);
      });
    }
  },
  mutations: {
    setPreferences(state, value) {
      state.data = value.filter(item => item.isDeleted != 1);
      state.all = value;
    },
    savePreferences(state, value) {
      let ids = [];
      value.forEach(item => {
        ids.push(item.idPreference);
      })
    
      state.data = [ ...state.data.filter(item => !ids.includes(item.idPreference)), ...value ];
    },
    deletePreference(state, value) {
      state.data = state.data.filter(item => item.idPreference != value.idPreference);
    }
  }
};

import api, { version } from "@/api/axios";
import md5 from "js-md5";
import storeCallback from "../../utils/storeCallback";

export default {
	state: {
		auth: false,
		settings: {},
		branch: {
			id: 0,
			name: '',
			address: '',
		},
		error: '',
		codeFlag: false
	},
	getters: {
		branchSettings: state => state.settings
	},
	actions: {
		login({ commit }, data) {
			if (!data.userPass && !data.clientEmail && !data.clientPhone && !data.userLogin && !data.clientCode) {
				commit("setAuthError", 'Заполните обязательные поля');
				storeCallback(data);
			} else {
				this.commit('WSStartUpdate');
				
				if (data.userPass) {
					data.userPass = String(data.userPass).trim();
					data.userPass = md5(data.userPass);
				}
				data.referer = document.referrer;
				
				api.post("/manage/auth/login", data).then(response => {
						commit("setAuth", response.data);
						storeCallback(data);
						this.commit('WSStopUpdate');
					})
					.catch(() => {
						commit("setAuthError", 'Что-то пошло не так, попробуйте позже.');
						storeCallback(data);
						this.commit('WSStopUpdate');
					});
			}
		},
		getAuth({ commit }) {
			api.post(`/manage/auth/auth-status?origin=${ window.location.origin }&pathname=${ window.location.pathname }`).then(response => {
				commit("setAuth", response.data);
			});
		},
		logout({ commit }, type = '') {
			this.commit('WSStartUpdate');
			api.post(`/manage/auth/logout?type=${ type }`).then(response => {
				commit("setAuth", response.data);
				this.commit('WSStopUpdate');
				
				window.location.href = '/';
			});
		},
		clearAuthError({ commit }) {
			commit("setAuthError", '');
		}
	},
	mutations: {
		setAuthError(state, value = '') {
			state.error = value;
		},
		setAuth(state, value) {
			if (!!value.status && !!value.groups) {
				value.isAdmin = !!value.groups.admins;
				value.isSuperUser = !!value.groups.superUsers;
				value.isMaster = !!value.groups.masters;
			}
			
			if (value.branch) {
				state.branch = value.branch;
				
				delete (value.branch);
			}
			
			if (value.error) {
				if (value.error.needCode) {
					
					state.codeFlag = true;
					
					if (value.error.message) {
						state.error = value.error.message;
					} else {
						state.error = '';
					}
				} else {
					if (value.error.message) {
						state.error = value.error.message;
					} else {
						state.error = value.error;
					}
				}
				
				delete (value.error);
			} else {
				state.error = '';
			}
			
			state.auth = value;

			if (!!value.settings) {
				state.settings = value.settings;
			}
			
			if (value.version !== version) {
				window.location.reload();
			}
		}
	}
};

<template>
  <div class="settings">
    <div class="head">
      <div class="name">
        <h1>Настройки</h1>
      </div>
    </div>

    <div class="content">
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="graphic" tab="Записи">
          <a-row>
            <a-col :span="10">
              <h2>График работы филиала</h2>

              <div class="help">
                <info-circle-outlined/>
                Сохраненный график будет установлен по умолчанию при выборе расписания
              </div>

              <div class="displayFlex mTop20">
                <a-select class="mRight10" v-model:value="model.schedule.start.hour" @change="handleChange('schedule')">
                  <a-select-option v-for="(hour, key) in timeHours()" :value="hour" :key="key">
                    {{ hour }} ч
                  </a-select-option>
                </a-select>
                <a-select v-model:value="model.schedule.start.minute" @change="handleChange('schedule')">
                  <a-select-option v-for="(minute, key) in timeMinutes()" :value="minute" :key="key">
                    {{ minute }} м
                  </a-select-option>
                </a-select>
                <span class="delimiter">—</span>
                <a-select class="mRight10" v-model:value="model.schedule.end.hour" @change="handleChange('schedule')">
                  <a-select-option v-for="(hour, key) in timeHours()" :value="hour" :key="key">
                    {{ hour }} ч
                  </a-select-option>
                </a-select>
                <a-select v-model:value="model.schedule.end.minute" @change="handleChange('schedule')">
                  <a-select-option v-for="(minute, key) in timeMinutes()" :value="minute" :key="key">
                    {{ minute }} м
                  </a-select-option>
                </a-select>
              </div>

              <a-button v-if="changed.schedule" class="mTop20" type="primary" @click="handleSave('schedule')">
                Сохранить
              </a-button>

              <h2 class="mBottom0 mTop20">Тип записей по умолчанию</h2>

              <div class="displayFlex mTop20">
                <a-select v-model:value="model.recordType" @change="handleChange('recordType')">
                  <a-select-option value="individual">
                    Идивидуальные
                  </a-select-option>
                  <a-select-option value="group">
                    Групповые
                  </a-select-option>
                </a-select>
              </div>

              <a-button v-if="changed.recordType" class="mTop20" type="primary" @click="handleSave('recordType')">
                Сохранить
              </a-button>
            </a-col>
          </a-row>
        </a-tab-pane>
        <a-tab-pane key="loyalty" tab="Программа лояльности">
          <div class="title displayFlexSBRW vAlignCenter">
            <h2>Программа лояльности</h2>

            <div class="displayFlexSBRN vAlignCenter">
              <a-checkbox :checked="model.bonusesFire == 1"
                          @change="(value) => handleChangeCheckbox(value, 'bonusesFire')">
                сгорание бонусов
              </a-checkbox>

              <template v-if="model.bonusesFire == 1">
                <div class="mRight5">Через</div>
                <a-input
                    size="small"
                    style="width: 100px;"
                    v-model:value="model.bonusesFireDays"
                    @change="handleChange('bonuses')"
                />
                <div class="mLeft5">дней</div>
              </template>

              <a-button size="small" v-if="changed.bonuses" class="mLeft20" type="primary"
                        @click="handleSave('bonuses')">
                Сохранить
              </a-button>
            </div>
          </div>

          <div class="card-wrap displayFlexSBRW">
            <div class=" width48" v-for="type in ['service', 'sales']" :key="type">
              <h3 class="mBottom0">{{ type == 'service' ? 'Услуги' : 'Продажи' }}</h3>
              <div>
                <a-table
                    size="small"
                    :data-source="numberSort(
                      model.loyalty.filter(item => item.loyaltyType == type && !item.shouldRemove),
                  'loyaltySumm'
                  )"
                    rowKey="idLoyalty"
                    :pagination="false"
                    :columns="columns"
                    :bordered="true"
                >
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'loyaltySumm'">
                      <a-input
                          v-model:value="record.loyaltySumm"
                          @change="handleChange('loyalty')"
                      />
                    </template>
                    <template v-if="column.key === 'loyaltyPercent'">
                      <a-input
                          v-model:value="record.loyaltyPercent"
                          @change="handleChange('loyalty')"
                      />
                    </template>
                    <template v-if="column.key === 'loyaltyFixed'">
                      <a-input
                          v-model:value="record.loyaltyFixed"
                          @change="handleChange('loyalty')"
                      />
                    </template>
                    <template v-if="column.key === 'actions'">
                      <a-tooltip title="удалить">
                        <delete-outlined
                            @click="removeLoyalty(record.idLoyalty)"
                        />
                      </a-tooltip>
                    </template>
                  </template>
                </a-table>

                <div class="group-child-footer">
                  <plus-circle-outlined @click="() => addLoyalty(type)"/>
                </div>
              </div>
            </div>

            <a-button v-if="changed.loyalty" class="mTop20" type="primary" @click="handleSave('loyalty')">
              Сохранить
            </a-button>
          </div>
        </a-tab-pane>
        <a-tab-pane key="tasks" tab="Задачи">
          <settings-tasks v-if="activeKey == 'tasks'"/>
        </a-tab-pane>
      </a-tabs>
      <div v-if="0" class="displayFlex mBottom20">
        <div class="card" style="width: 30%">
          <div class="title">
            <h2>Часовой пояс</h2>
          </div>

          <div class="card-wrap">
            <div>
              <a-select @change="handleChange('timeZone')"></a-select>

              <a-button v-if="changed.timeZone" class="mTop20" type="primary" @click="handleSave('timeZone')">
                Сохранить
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defaultLoyalty, defaultSchedule } from "@/utils/defaults";
import { timeHours, timeMinutes } from "@/utils/time";
import { mapActions, mapGetters } from "vuex";
import { GET_ERROR_MESSAGE } from "@/utils/messages";
import { numberSort } from "@/utils/sort";
import SettingsTasks from "@/views/data/settings/tasks";

const columns = [
  {
    title: "Сумма, от",
    dataIndex: "loyaltySumm",
    key: "loyaltySumm"
  },
  {
    title: "Бонус",
    children: [
      {
        title: "Процент",
        dataIndex: "loyaltyPercent",
        key: "loyaltyPercent"
      },
      {
        title: "Фиксированная, руб.",
        dataIndex: "loyaltyFixed",
        key: "loyaltyFixed"
      }
    ]
  },
  {
    title: "",
    key: "actions"
  }
]

export default {
  name: "CalendarSchedule",
  components: { SettingsTasks },
  data: function () {
    return {
      columns,
      numberSort,
      timeHours,
      timeMinutes,
      activeKey: 'graphic',
      model: {
        ...defaultSchedule(),
        loyalty: [],
        recordType: undefined,
        certificateGroup: '',
        bonusesFire: 0,
        bonusesFireDays: undefined,
      },
      changed: {
        recordType: false,
        schedule: false,
        loyalty: false,
        bonuses: false,
        timeZone: false,
      }
    }
  },
  computed: {
    ...mapGetters({
      auth: "auth",
      settings: "settings",
      loyalty: "loyalty"
    })
  },
  created() {
    this.init()
  },
  methods: {
    ...mapActions([
      "startLoading",
      "stopLoading",
      "getApiData",
      "setApiData"
    ]),

    init() {
      this.startLoading('Получение данных')

      Promise.all([
        this.getApiData({
          type: 'Settings'
        }),
        this.getApiData({
          type: 'Loyalty'
        }),
      ]).then(() => {
        this.model = { ...this.model, ...this.settings };
        this.model.loyalty = this.loyalty;

        this.stopLoading();
      }).catch((e) => {
        console.error(e);
        this.startLoading({ message: GET_ERROR_MESSAGE, status: 'error', close: true });
      })
    },

    handleChange(type) {
      this.changed[type] = true;
    },

    handleChangeCheckbox(value) {
      this.model.bonusesFire = !!value.target && !!value.target.checked ? 1 : 0;
      this.handleChange('bonuses');
    },

    handleSave(type) {
      this.startLoading('Сохранение данных')

      if (type == 'loyalty') {
        this.setApiData({
          type: 'Loyalty',
          data: this.model.loyalty
        }).then(() => {
          this.changed.loyalty = false;
          this.stopLoading({ status: 'success', close: true });
        }).catch(() => {
          this.startLoading({ message: 'Ошибка сохранния данных', status: 'error', close: true });
        })
      } else {
        const data = type == 'bonuses' ? [
          {
            settingName: 'bonusesFire',
            settingValue: this.model.bonusesFire,
          },
          {
            settingName: 'bonusesFireDays',
            settingValue: this.model.bonusesFireDays,
          }
        ] : [ {
          settingName: type,
          settingValue: [ 'certificateGroup', 'recordType' ].includes(type) ? this.model[type] : JSON.stringify(this.model[type])
        } ]
        this.setApiData({
          type: 'Settings',
          data
        }).then(() => {
          this.changed[type] = false;
          this.stopLoading({ status: 'success', close: true });
        }).catch((e) => {
          console.error(e);
          this.startLoading({ message: 'Ошибка сохранния данных', status: 'error', close: true });
        })
      }
    },

    addLoyalty(type) {
      if (!this.model.loyalty) {
        this.model.loyalty = [ defaultLoyalty(type) ]
      } else {
        this.model.loyalty = [ ...this.model.loyalty, defaultLoyalty(type) ]
      }
    },

    removeLoyalty(idLoyalty) {
      this.model.loyalty = this.model.loyalty.map(item => item.idLoyalty === idLoyalty ? {
        ...item,
        shouldRemove: true
      } : item);
    }
  }
};
</script>

<style lang="less" scoped>
.head {
  border: 0;
}

.content {
  height: calc(100vh - 70px);
}

@import "../../../layouts/style/cards.less";
</style>
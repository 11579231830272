import api from "@/api/axios";

export default {
	state: {
		data: [],
	},
	actions: {
		getTimetable({commit}, data) {
			return api.get("/manage/timetable/get", {params: data}).then(response => {
				commit("clearTimetable");
				commit("setTimetable", response.data);
				
				return response.data;
			});
		},
		saveTimetable({commit}, data) {
			return api.post("/manage/timetable/set", data).then(response => {
				if (response.data.error) {
					return response.data.error;
				} else {
					commit("setTimetable", response.data);
				}
			});
		},
		deleteTimetable({commit}, data) {
			return api.post("/manage/timetable/delete", data).then(() => {
				commit("deleteTimetable", data);
			});
		},
	},
	mutations: {
		setTimetable(state, value) {
			let ids = [];
			if (state.data.length > 0) {
				value.forEach(item => {
					ids.push(item.idTimetable);
				})
			}
			
			state.data = [...(ids.length > 0 ? state.data.filter(item => !ids.includes(item.idTimetable)) : state.data), ...value];
		},
		deleteTimetable(state, value) {
			state.data = state.data.filter(item => !value.idTimetable.includes(item.idTimetable));
		},
		clearTimetable(state) {
			state.data = [];
		}
	}
};

import sleep from "@/utils/sleep";

const defaultErrors = () => ({
	timetable: {},
	delivery: {},
	reportMonth: {}
})

const isNull = (value, canBeZero = true) => {
	return value == undefined || value === '' || value == 0 && canBeZero
};

export default {
	state: {
		closingStatus: false,
		closingErrors: defaultErrors(),
		closingPercent: 0, //0% - 100%
	},
	getters: {
		closingStatus: state => state.closingStatus,
		closingErrors: state => state.closingErrors,
		closingPercent: state => state.closingPercent,
	},
	actions: {
		handleClearCloseDay({ commit }) {
			commit("clearCloseDay");
		},
		
		async closeDay({ commit }, data) {
			commit("startCheck");
			await sleep(1000);
			
			let cashSum = 0;
			
			data.timetable.forEach(item => {
				if (isNull(item.idClient)) {
					commit("addErrors", [ 'timetable', item.idTimetable, 'не выбран клиент' ])
				}
				
				if (item.timetableCash) {
					cashSum += Number(item.timetableCash);
				}
				
				if (item.report) {
					item.report.forEach(report => {
						if (report.reportType === 'service') {
							if (isNull(report.idService)) {
								commit("addErrors", [ 'timetable', item.idTimetable, 'не выбрана услуга' ])
							}
							
							if (report.reportType === 'sales') {
								if (isNull(report.idSale)) {
									commit("addErrors", [ 'timetable', item.idTimetable, 'не выбран товар' ])
								}
								
								if (isNull(report.reportCount)) {
									commit("addErrors", [ 'timetable', item.idTimetable, 'не выбрано количество товара' ])
								}
							}
						}
					})
				}
			})
			
			commit("setPercent", 1);
			await sleep(1000);
			
			data.delivery.forEach(item => {
				if (isNull(item.deliveryCount)) {
					commit("addErrors", [ 'delivery', item.idDelivery, 'не выбрано количество' ])
				}
				
				if (isNull(item.deliveryName) && isNull(item.idSale)) {
					commit("addErrors", [ 'delivery', item.idDelivery, 'не выбран товар или не введено название' ])
				}
			});
			
			commit("setPercent", 2);
			await sleep(1000);
			
			if (!!data.reportMonth) {
				const totalData = data.reportMonth.reportMonthTotalData;
				let checkStartSum = true;
				let checkEndSum = true;
				
				if (
					!!totalData.reportSummEndPrev &&
					!!totalData.reportSummStart &&
					Number(totalData.reportSummEndPrev) !== Number(totalData.reportSummStart)
				) {
					checkStartSum = this.reportSummEndPrev;
				}
				
				if (!!totalData.reportSummStart && !!totalData.reportCollection) {
					const summ = Number(totalData.reportSummStart) -
						cashSum +
						Number(totalData.reportCollection || 0) +
						Number(totalData.reportSummAdd || 0);
					
					const endSumm = Math.abs(Number(totalData.reportSummEnd) - Number(summ)).toFixed(0);
					if (endSumm > 100) {
						checkEndSum = Number(summ);
					}
				}
				
				if (
					isNull(totalData) ||
					isNull(totalData.reportSummStart, false) ||
					Number.isInteger(checkStartSum)
				) {
					commit("addErrors", [ 'reportMonth', 0, 'не сходится сумма с утра по факту' ])
				}
				
				if (
					isNull(totalData) ||
					isNull(totalData.reportSummEnd, false) ||
					Number.isInteger(checkEndSum)
				) {
					commit("addErrors", [ 'reportMonth', 0, 'не сходится сумма в конце дня' ])
				}
			}
			
			commit("endCheck");
			
			setTimeout(() => {
				commit("closeCheck");
			}, 2000)
		},
	},
	mutations: {
		startCheck(state) {
			state.closingErrors = defaultErrors();
			state.closingStatus = true;
			state.closingPercent = 10;
		},
		
		clearCloseDay(state) {
		
		},
		
		closeCheck(state) {
			state.closingStatus = false;
			state.closingPercent = 0;
		},
		
		endCheck(state) {
			let isError = false;
			Object.values(state.closingErrors).forEach(item => {
				if (Object.values(item).length > 0) {
					isError = true;
				}
			})
			
			if (isError) {
				state.closingStatus = 'error';
			} else {
				state.closingStatus = false;
			}
			state.closingPercent = 100;
		},
		
		addErrors(state, value) {
			const key = value[0];
			const id = value[1];
			const message = value[2];
			
			let errors = state.closingErrors;
			
			if (!!errors[key]) {
				if (!!errors[key][id]) {
					errors[key][id].push(message);
				} else {
					errors[key][id] = [ message ];
				}
			}
			
			state.closingErrors = errors;
		},
		
		setPercent(state, value) {
			state.closingPercent = Object.keys(defaultErrors()).length * value * 10;
		}
	}
};

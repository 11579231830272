<template>
  <div class="center-form">
    <form class="app-form">
      <div class="app-form-head">
        <h1>Вход в систему</h1>
        <span>введите ваш логин и пароль<br>чтобы получить доступ обратитесь к администратору</span>
      </div>

      <div class="app-form-body">
        <label>
          Логин:
          <a-input v-model:value="email" placeholder="Логин (E-mail):">
            <template #prefix>
              <user-outlined/>
            </template>
            <template #suffix>
              <a-tooltip
                  v-if="authError && authError.userLogin && authError.userLogin[0]"
                  @click="authError.userLogin = false"
                  :title="authError.userLogin[0]"
              >
                <info-circle-outlined style="color: red"/>
              </a-tooltip>
            </template>
          </a-input>
        </label>

        <label>
          Пароль:
          <a-input-password v-model:value="pass" placeholder="Пароль:">
            <template #preffix>
              <a-tooltip
                  v-if="authError && authError.userPass && authError.userPass[0]"
                  @click="authError.userPass = false"
                  :title="authError.userPass[0]"
              >
                <info-circle-outlined style="color: red"/>
              </a-tooltip>
            </template>
          </a-input-password>
        </label>
      </div>

      <div class="app-form-footer displayFlexSBRW">
        <a-checkbox class="mTop10" v-model:checked="rememberMe">
          - запомнить меня
        </a-checkbox>
        <a-button
            type="primary"
            size="large"
            @click="handleLogin"
        >
          Войти
        </a-button>
      </div>
    </form>

    <div class="copyrights displayFlexSBRW">
      <span>©2019 - {{ year }}</span>
      <div>
        <a>Конфиденциальность</a>
        <a>Условия</a>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "UserAuth",
  data() {
    return {
      email: "",
      pass: "",
      rememberMe: true,
      year: moment().format('YYYY')
    };
  },

  computed: {
    ...mapGetters([ "auth", "authError" ])
  },

  methods: {
    ...mapActions([ "login", "startLoading", "stopLoading" ]),

    handleLogin() {
      this.startLoading();
      this.login({
        userLogin: this.email,
        userPass: this.pass,
        rememberMe: this.rememberMe,
        callback: () => {
          this.stopLoading();
        }
      });
    }
  }
};
</script>

<style lang="less">
.center-form {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
  }
}

.copyrights {
  width: 460px;
  padding: 10px 7px;

  a {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    margin-left: 20px;
  }
}
</style>

<template>
  <h2>Статусы выполнения</h2>

  <a-skeleton v-if="loading.statuses" active/>
  <div class="tasks-wrap" v-else>
    <Sortable
        v-if="modelStatuses.length > 0"
        :list="modelStatuses"
        item-key="taskStatusOrder"
        @end="handleStatusSort"
    >
      <template #item="{element, index}">
        <div class="draggable" :key="element.taskStatusName">
          <div class="left">
            <VerticalAlignMiddleOutlined/>

            <div class="name tasks-status-type" :class="'type' + element.taskStatusType">
              {{ element.taskStatusName }}
            </div>
          </div>

          <a-button class="hidden" size="small" @click="() => handleEditStatus(index)">
            <template #icon>
              <EditOutlined/>
            </template>
          </a-button>
        </div>
      </template>
    </Sortable>

    <a-modal
        wrapClassName="top-z-index-model"
        :visible="model.status.editable"
        :footer="false"
        :closable="false"
    >
      <h4>Название</h4>
      <a-input v-model:value="model.status.taskStatusName"/>
      <h4 class="mTop10">Тип</h4>
      <a-select v-model:value="model.status.taskStatusType">
        <a-select-option value="0">В ожидании</a-select-option>
        <a-select-option value="1">Выполняется</a-select-option>
        <a-select-option value="2">Готово</a-select-option>
      </a-select>

      <div class="buttons mTop20">
        <a-button size="small" @click="handleNoEditStatus">Отменить</a-button>
        <a-button class="mLeft10" size="small" type="primary" @click="handleSaveStatus">Применить</a-button>
      </div>
    </a-modal>

    <div class="add-button" @click="handleAddStatus">
      <plus-circle-outlined/>
      Добавить новый статус
    </div>

    <div class="save-button" v-if="changing.statuses">
      <a-button size="small" @click="initStatuses">Отменить</a-button>
      <a-button size="small" type="primary" @click="handleSaveStatuses">Сохранить</a-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Sortable } from "sortablejs-vue3";
import lodash from 'lodash';
import '@cyhnkckali/vue3-color-picker/dist/style.css'
import { defaultTaskStatuses } from "@/utils/tasks";
import { orderSort } from "@/utils/sort";

const defaultTaskStatus = (order = 1000, editable = true) => ({
  taskStatusName: '',
  taskStatusType: '1',
  taskStatusOrder: order,
  editable: editable
});

export default {
  name: "SettingsTasksStatuses",
  components: { Sortable },
  data: function () {
    return {
      model: {
        status: defaultTaskStatus(1000, false),
        statuses: defaultTaskStatuses(),
        statusesChanged: Array.from({ length: defaultTaskStatuses().length }, (v, i) => i),
      },
      changing: {
        statuses: false,
      },
      loading: {
        statuses: true,
      }
    }
  },
  computed: {
    ...mapGetters({
      auth: "auth",
      settings: "settings",
      taskStatuses: "taskStatuses",
    }),

    modelStatuses() {
      return orderSort(this.model.statuses, 'taskStatus')
    },
  },
  watch: {
    taskStatuses() {
      this.init()
    }
  },
  created() {
    this.initStatuses()
  },
  methods: {
    ...mapActions([
      "startLoading",
      "stopLoading",
      "getApiData",
      "setApiData"
    ]),

    initStatuses() {
      this.loading.statuses = true;
      this.getApiData({ type: 'TaskStatuses' }).then(data => {
        if (data) {
          this.setStatuses(data)
        }
      })
    },

    setStatuses(data) {
      setTimeout(() => {
        this.init();

        this.model.statusesChanged = Array.from({ length: this.model.statuses.length }, (v, i) => i)
        this.changing.statuses = false;
        this.loading.statuses = false;
      }, 400);
    },

    init() {
      this.model.statuses = this.taskStatuses;
    },

    handleAddStatus() {
      let maxOrder = 0;
      this.model.statuses.forEach(item => {
        if (item.taskStatusOrder > maxOrder || maxOrder == 0) {
          maxOrder = item.taskStatusOrder;
        }
      })

      maxOrder += 10;

      this.model.statusesChanged.push(this.model.statusesChanged.length)
      this.model.statuses.push(defaultTaskStatus(maxOrder, false))
      this.handleEditStatus(this.model.statuses.length - 1)
    },

    handleStatusSort(event) {
      let newStatuses = [];

      this.model.statusesChanged.forEach((item, key) => {
        if (key == event.oldIndex) {

        } else {
          if (event.oldIndex < event.newIndex) {
            newStatuses.push(item);
          }

          if (key == event.newIndex) {
            newStatuses.push(event.oldIndex);
          }

          if (event.oldIndex > event.newIndex) {
            newStatuses.push(item);
          }
        }
      })

      this.model.statusesChanged = newStatuses
      this.changing.statuses = true
    },

    handleEditStatus(key) {
      this.model.status = { ...lodash.cloneDeep(this.model.statuses[key]), editable: true, key: key };
    },

    handleNoEditStatus() {
      this.model.status = defaultTaskStatus(1000, false);
    },

    handleSaveStatus() {
      this.model.statuses[this.model.status.key] = lodash.cloneDeep(this.model.status);
      this.changing.statuses = true;

      this.handleNoEditStatus();
    },

    handleSaveStatuses() {
      this.startLoading();

      let statuses = [];
      let counter = 10;
      this.model.statusesChanged.forEach(item => {
        statuses.push({ ...this.model.statuses[item], taskStatusOrder: counter });

        counter += 10;
      })

      this.setApiData({
        type: 'TaskStatuses', data: statuses
      }).then(data => {
        this.loading.statuses = true;
        this.changing.statuses = false;
        this.model.statuses = [];

        this.setStatuses(data)

        this.startLoading({ message: 'Статусы сохранены', status: 'success', close: true });
      }).catch((e) => {
        console.error(e);
        this.startLoading({ message: 'Ошибка сохранения', status: 'error', close: true });
      })
    },
  }
};
</script>

<style lang="less" scoped>
h2 {
  margin-bottom: 10px;
}

.tasks-wrap {
  margin-bottom: 30px;
  width: 300px;

  &:last-child {
    margin-bottom: 0;
  }

  .save-button {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: end;

    .ant-btn {
      margin-left: 20px;
    }
  }

  .add-button {
    margin-top: 10px;
    padding: 6px 10px 6px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      color: #4096ff;
    }

    .anticon {
      font-size: 20px;
      margin-right: 12px;
    }
  }

  .draggable {
    border: 1px #f0f0f0 solid;
    margin-top: -1px;
    background: white;
    cursor: pointer;
    padding: 6px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
    }

    .anticon-vertical-align-middle {
      margin-right: 10px;
    }

    .ant-btn.hidden {
      display: none;
    }

    &:hover {
      .ant-btn.hidden {
        display: block;
      }
    }

    .name {
      height: 24px;
      line-height: 24px;
      padding: 0 6px;
      border-radius: 5px;
    }
  }
}
</style>

<style lang="less">
.tasks-type-new-modal {
  .colors {
    display: flex;
    justify-content: space-between;
  }

  .ck-cp-container {
    width: 230px;
    box-shadow: none;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 8px;

    .colour-area, .colour-area-mask {
      border-radius: 4px;
    }
  }
}
</style>
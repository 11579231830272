import dayjs from "dayjs";
import api from "@/api/axios";

export default {
	state: {
		reportMonth: undefined,
		reportMonthPrev: undefined
	},
	getters: {
		reportMonth: state => state.reportMonth,
		reportMonthPrev: state => state.reportMonthPrev,
	},
	actions: {
		getCurrentReportMonth({ commit }, date) {
			return api.get(`/manage/report-month/get`, {
				params: {
					dateEnd: date.currentDate,
					dateStart: date.prevDate
				}
			}).then(response => {
				const first = response.data[0];
				const second = response.data[1];
				
				if (!!first && first.reportMonthDate == date.currentDate) {
					commit("setReportMonthCurrent", first);
					commit("setReportMonthPrev", second);
				} else if (!!second) {
					commit("setReportMonthPrev", first);
					commit("setReportMonthCurrent", second);
				} else {
					commit("setReportMonthPrev", undefined);
					commit("setReportMonthCurrent", undefined);
				}
			});
		},
		setCurrentReportMonth({ commit }, data) {
			commit("setReportMonthCurrent", data);
		}
	},
	mutations: {
		setReportMonthCurrent(state, value) {
			state.reportMonth = value;
		},
		setReportMonthPrev(state, value) {
			state.reportMonthPrev = value;
		},
	}
};

import { getJsonStorage, setJsonStorage } from "@/utils/localStorage";

const FILTERS_KEY = 'tasks_filters_key';

export default {
	state: {
		filters: getJsonStorage(FILTERS_KEY) || {
			searchText: '',
			users: {}
		}
	},
	getters: {
		taskFilters: state => state.filters
	},
	actions: {
		setTaskFilters({ commit }, data) {
			if (data.name && data.value) {
				commit('setTaskFilters', data);
			}
		}
	},
	mutations: {
		setTaskFilters(store, data) {
			store.filters[data.name] = data.value;
			
			setJsonStorage(FILTERS_KEY, store.filters)
		}
	}
};

import api from "@/api/axios";
import storeCallback from "../../utils/storeCallback";

export default {
	state: {
		data: [],
	},
	actions: {
		getWarehouses({commit}, data) {
			return api.get("/manage/warehouses/get").then(response => {
				commit("setWarehouses", response.data);
				storeCallback(data);
			});
		},
		saveWarehouse({commit}, data) {
			api.post("/manage/warehouses/set", data.data).then(() => {
				commit("addWarehouse", data.data);
				storeCallback(data);
			}).catch(() => {
				storeCallback(data, data.data);
			});
		},
		deleteWarehouses({commit}, data) {
			api.post("/manage/warehouses/delete", data.data).then(() => {
				commit("deleteWarehouse", data.data);
				storeCallback(data);
			}).catch(() => {
				storeCallback(data, data.data);
			});
		},
	},
	mutations: {
		addWarehouse(state, value) {
			state.data = [...state.data, value];
		},
		setWarehouses(state, value) {
			state.data = value.filter(item => item.isDeleted != 1);
		},
		deleteWarehouse(state, value) {
			state.data = state.data.filter(item => item.idWarehouse !== value.idWarehouse);
		}
	}
};

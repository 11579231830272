<template>
  <div class="displayFlex">
    <div v-for="(status, key) in changes" class="mRight10" :key="key">
      <div class="all-changed">
        <a-tooltip placement="bottom" :title="`${namesView[key]} - ${statuses[status]}`">
          <check-outlined v-if="status === 'success'" />
          <a-spin v-else-if="status === 'loading'"/>
          <close-outlined style="color:red;" v-else-if="status === 'error'" />
        </a-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {mapActions, mapGetters} from "vuex";
import {namesView, statuses} from "@/utils/changes";

export default {
  name: "ChangeLoader",
  data: function () {
    return {
      namesView,
      statuses
    };
  },
  watch: {
    isChanged() {
      this.lastChanged = moment().unix();
    }
  },
  computed: {
    ...mapGetters({
      changes: 'changes'
    })
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions([
      'checkChanges'
    ]),
    init() {
      setInterval(() => {
        this.checkChanges()
      }, 1000);
    }
  }
};
</script>

<style scoped>
	.all-changed {
		background: white;
		width: 30px;
		height: 30px;
		border-radius: 30px;
		font-size: 20px;
		color: #1890ff;
		text-align: center;
		line-height: 30px;
		margin-top: 5px;
	}
</style>
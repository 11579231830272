<template>
  <div class="wrap">
    <div class="main-text displayFlexSCRN vAlignCenter">
      <div>
        <span class="title">
          Система контроля студии MARMELAD
        </span>
        <span>
          для перехода в нужный раздел, воспользуйтесь меню в шапке сайта
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
  mounted() {},
  methods: {}
};
</script>

<style lang="less" scoped>
.main-text {
  height: calc(100vh - 49px);
  text-align: center;

  span {
    color: black;
    display: block;
    font-size: 12px;

    &.title {
      font-size: 18px;

      color: #1890ff;
      font-weight: bold;
    }
  }
}
</style>

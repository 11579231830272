export const costPrices = 'costPrices';
export const services = 'service';
export const sales = 'sales';
export const delivery = 'delivery';
export const total = 'total';
export const clients = 'clients';
export const users = 'users';
export const preferences = 'preferences';
export const consumables = 'consumables';
export const monthReport = 'monthReport';

export const namesView = {
    [costPrices]: 'Услуги, себестоимость',
    [services]: 'Услуги',
    [sales]: 'Продажи',
    [delivery]: 'Доставка',
    [total]: 'Касса',
    [clients]: 'Работа с клиентами',
    [users]: 'Сотрудники',
    [preferences]: 'Предпочтения',
    [consumables]: 'Расходники',
    [monthReport]: 'Ежемесячный отчет',
}

export const statuses = {
    success: 'изменения сохранены',
    loading: 'изменения сохраненяются',
    error: 'ошибка при сохранении',
}
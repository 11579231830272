import api from "@/api/axios";
import storeCallback from "../../utils/storeCallback";

export default {
	state: {
		data: [],
		all: [],
		groups: [],
		groupsAll: []
	},
	actions: {
		getSales({commit}, data) {
			return api.get("/manage/sales/get" + (!!data && data.all ? '?all=1' : '')).then(response => {
				commit("setSales", response.data);
				storeCallback(data);
			});
		},
		saveSale(c, data) {
			api.post("/manage/sales/set", data.data).then(() => {
				storeCallback(data);
			}).catch(() => {
				storeCallback(data, data.data);
			});
		},
		deleteSale({commit}, data) {
			api.post("/manage/sales/delete", data.data).then(() => {
				commit("deleteSale", data.data);
				storeCallback(data);
			}).catch(() => {
				storeCallback(data, data.data);
			});
		},
		getSaleGroups({commit}, data) {
			return api.get("/manage/sales/get-groups").then(response => {
				commit("setSaleGroup", response.data);
				storeCallback(data);
			});
		},
		saveSaleGroup({commit}, data) {
			api.post("/manage/sales/set-group", data.data).then(() => {
				commit("addSaleGroup", data.data);
				storeCallback(data);
			}).catch(() => {
				storeCallback(data, data.data);
			});
		},
	},
	mutations: {
		setSales(state, value) {
			state.data = value.filter(item => item.isDeleted != 1);
			state.all = value;
		},
		saveSales(state, value) {
			let ids = [];
			value.forEach(item => {
				ids.push(item.idSale);
			})
			
			state.data = [ ...state.data.filter(item => !ids.includes(item.idSale)), ...value ];
			state.all = [ ...state.all.filter(item => !ids.includes(item.idSale)), ...value ];
		},
		addSaleGroup(state, value) {
			state.groups = [...state.groups, ...value];
			state.groupsAll = [...state.groupsAll, ...value];
		},
		setSaleGroup(state, value) {
			state.groups = value.filter(item => item.isDeleted != 1);
			state.groupsAll = value;
		},
		deleteSale(state, value) {
			state.data = state.data.filter(item => item.idSale != value.idSale);
		}
	}
};

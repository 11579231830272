const { floor, abs, random } = Math;

const RANDOM_PART_LEN = 5;
const TIMESTAMP_PART_LEN = 7;

const BASE_TIMESTAMP = Date.UTC(2019, 6, 23, 0, 0, 0, 0);
const ID_LEN = TIMESTAMP_PART_LEN + RANDOM_PART_LEN;
const BASE62_CHARS = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

export default () => {
	const
		timestamp = Date.now(),
		randomPart = generateRandomString(RANDOM_PART_LEN);

	if (timestamp < BASE_TIMESTAMP) {
		throw new Error(`timestamp must be equal or greater than base timestamp: ${BASE_TIMESTAMP}`);
	}
	const id = encodeNumberAsBase62(timestamp - BASE_TIMESTAMP, TIMESTAMP_PART_LEN) + randomPart;
	if (id.length !== ID_LEN) {
		throw new Error(`failed to generate id: length is invalid (id=${id})`);
	}
	return id;
};

const generateRandomString = (len) => {
	const s = [];
	for (let i = 0; i < len; i++) {
		s.push(BASE62_CHARS.charAt(floor(random() * BASE62_CHARS.length)));
	}
	return s.join('');
};

function encodeNumberAsBase62(n, len) {
	n = floor(abs(n));
	const s = [];
	while (n !== 0) {
		s.push(BASE62_CHARS.charAt(n % 62));
		n = floor(n / 62);
	}
	while (s.length < len) {
		s.push('0');
	}
	return s.reverse().join('');
}

export const certificateId = '19q2g5fAK9xI';
import moment from "moment";
import generateId from "@/utils/generateId";
import dayjs from "dayjs";
import { clientSources } from "@/utils/constants";

export const defaultInterval = () => ({
	hour: 0,
	minute: 0
})

export const defaultMasterData = () => ({
	salarySale: 0,
	salaryService: 0,
	salary: 0,
	isSalary: false,
	adminCash: 0,
	adminCashPercent: 0,
	services: [],
	sales: [],
})

export const defaultSchedulePause = () => ({
	service: '',
	scheduleMaxUsers: '',
	scheduleGroupRecord: '',
	idService: '',
	start: defaultInterval(),
	end: defaultInterval()
})

export const defaultSchedule = (settings = false) => {
	let schedule = {
		schedule: defaultSchedulePause()
	}
	
	if (settings !== false) {
		if (settings.schedule != undefined && !!settings.schedule && !!settings.schedule.start) {
			schedule.schedule.start.hour = Number(settings.schedule.start.hour);
			schedule.schedule.start.minute = Number(settings.schedule.start.minute);
			schedule.schedule.end.hour = Number(settings.schedule.end.hour);
			schedule.schedule.end.minute = Number(settings.schedule.end.minute);
		}
	}
	
	return schedule
}

export const defaultUser = () => ({
	idUser: 0,
	userName: ''
})

export const defaultLoyalty = (type) => ({
	idLoyalty: generateId(),
	loyaltyType: type,
	loyaltySumm: 0,
	loyaltyPercent: undefined,
	loyaltyFixed: undefined,
})

export const clientSourceParams = {
	writtenDown: 0,
	appeals: 0
}

export const defaultClientSource = {
	instaWrittenDown: 0,
	instaAppeals: 0,
	
	messengerWrittenDown: 0,
	messengerAppeals: 0,
	
	callsWrittenDown: 0,
	callsAppeals: 0,
	
	offlineWrittenDown: 0,
	offlineAppeals: 0,
	
	vkWrittenDown: 0,
	vkAppeals: 0,
};

export const defaultReportMonthTotalData = () => ({
	reportCollection: undefined,
	reportSummAdd: undefined,
	reportSummEnd: undefined,
	reportSummStart: undefined,
	clientSources: defaultClientSource,
	nextTime: { potential: 0, recording: 0 },
	oneMore: { potential: 0, recording: 0 }
});

export const defaultReport = (type = 'service', idService = undefined, idTimetable = undefined) => ({
	idReport: generateId(),
	reportType: type,
	idClient: undefined,
	idService: idService,
	idSale: undefined,
	reportCash: undefined,
	reportCard: undefined,
	reportQR: undefined,
	reportMasterCash: undefined,
	idPreference: '',
	reportDiscountValue: '',
	reportDiscountType: 1,
	idTimetable: idTimetable,
	reportCount: type == 'sales' ? 1 : undefined
})

export const defaultCertificate = (
	{
		idCertificate,
		idUser = undefined,
		certificateDate = dayjs(),
		certificateDateTo = dayjs(),
		certificateNumber = undefined,
		certificateBalance = undefined,
		certificateActive = undefined,
		idClient = undefined,
		idSale = undefined,
		idReport = undefined,
	}
) => ({
	idCertificate,
	idUser,
	certificateDate,
	certificateDateTo,
	certificateNumber,
	certificateBalance,
	certificateActive,
	idClient,
	idSale,
	idReport,
})

export const defaultBonuses = () => ({
	checked: 3,
	writeOff: undefined
})

export const defaultTimetableCertificate = () => ({
	checked: 3,
	writeOff: undefined
})

export const defaultTimetableClient = (idClient = undefined) => ({
	idClient,
	individual: '',
	timetableCash: '',
	timetableCard: '',
	timetableQR: '',
	timetableBonus: 0,
	timetableStatus: 0,
	timetableClientIndividualPrice: 0,
	timetableCertificate: 0,
	timetableClientNote: '',
	timetableClientDiscountValue: undefined,
	timetableClientDiscountType: 1,
	writeOffCertificate: 0,
	idCertificate: undefined,
	writeOffBonuses: 0,
})

export const defaultTimetable = () => ({
	idTimetable: generateId(),
	idClient: undefined,
	idUser: 0,
	timetableDate: moment(),
	timetableStartHour: '08',
	timetableStartMinute: '00',
	timetableTimeHour: 0,
	timetableTimeMinute: 0,
	timetableColor: 'lightblue',
	timetableNote: '',
	
	timetablePriceFrom: 0,
	timetablePriceUpTo: 0,
	
	idPreference: undefined,
	
	bonus: [],
	reports: [],
	
	reportsSales: [],
	
	removedReport: [],
	
	timetableStart: '08:00',
	timetableEnd: '09:00',
	
	newTimetable: true,
	clients: [ defaultTimetableClient() ]
})

export const timetableStatuses = {
	0: 'Ожидание',
	1: 'Запись подтверждена',
	2: 'Клиент не пришел',
	3: 'Клиент пришел',
	4: 'Услуга оказана'
}

export const colours = [
	"#F4A460",
	"#FF00FF",
	"#CD5C5C",
	"#00FFFF",
	"#FF4500",
	"#DB7093",
	"#73be28",
	"#DC143C"
];

export const timetableColours = [
	{
		key: 'lightblue',
		name: 'Голубой',
		color: "rgba(230, 247, 255, 0.7)"
	},
	{
		key: 'red',
		name: 'Красный',
		color: "rgba(244, 67, 54, 0.7)"
	},
	{
		key: 'purple',
		name: 'Фиолетовый',
		color: "rgba(156, 39, 176, 0.7)"
	},
	{
		key: 'blue',
		name: 'Синий',
		color: "rgba(33, 150, 243, 0.7)"
	},
	{
		key: 'green',
		name: 'Зеленый',
		color: "rgba(76, 175, 80, 0.7)"
	},
	{
		key: 'yellow',
		name: 'Желтый',
		color: "rgba(255, 235, 59, 0.7)"
	},
	{
		key: 'orange',
		name: 'Оранжевый',
		color: "rgba(250, 149, 0, 0.7)"
	},
	{
		key: 'brown',
		name: 'Коричневый',
		color: "rgba(121, 85, 72, 0.7)"
	},
	{
		key: 'gray',
		name: 'Серый',
		color: "rgba(158, 158, 158, 0.7)"
	},
]
export const clientSources = {
	insta: 'Инстаграм',
	messenger: 'Мессенжеры',
	calls: 'Звонок',
	offline: 'Зашедших в студию',
	vk: 'Вконтакте',
}

export const notificationsThemes = {
	'ChangeTimetable': 'Запись изменена',
	'DeleteTimetable': 'Запись удалена',
	'CreateTimetable': 'Создана новая зпись',
	'ErrorMessage': 'Поступило сообщение об ошибке',
}